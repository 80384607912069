import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

import '../styles/documentation.scss';

const Template = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  
  return (
    <Layout title="Docs">
      <div className="documentation-page">
        <h1>{frontmatter.title}</h1>
        
        <div
          className="documentation-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  );
};

export default Template;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;